<template>
  <div>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <v-select
              label="calendarName"
              id="calendar-type"
              multiple
              :clear-search-on-blur="(clearSearchOnSelect, multiple) => clearSearchOnSelect && !multiple"
              :options="calendarTypeOptions"
              v-model="data.selectedCalendarTypes"
              @open="focusedCalendar = true"
              @close="focusedCalendar = false"
              @input="getSelectedCalendarCategories"
              :class="{
                'is-value-exist': data.selectedCalendarTypes.length > 0 || focusedCalendar,
                'is-invalid': !data.selectedCalendarTypes.length && formSubmitted,
              }"
              :disabled="multilingualToggle"
            >
              <template slot="no-options" slot-scope="{ search }">
                {{
                  search
                    ? `No calendar found.`
                    : `Start typing calendar, and select it from the
                      dropdown.`
                }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.calendarName }}
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.calendarName }}
              </template>
            </v-select>
            <label for="calendar-type"
              >Calendar
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!data.selectedCalendarTypes.length && formSubmitted">Calendar required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="data.selectedCalendarTypes.length">
      <b-col sm="12" md="10" lg="10" xl="8">
        <b-form-group>
          <h4 class="page-sub-header">
            Calendar categories
            <InheritText v-if="multilingualToggle" />
          </h4>
          <b-form-row>
            <b-col cols="12" sm="4" v-for="(option, index) in data.categories" :key="index">
              <b-form-checkbox
                :checked="option.isSelected"
                :v-modal="option.isSelected"
                @input="option.isSelected = $event"
                :id="'calendar-category-' + index"
                :name="'calendar-category-' + index"
                :disabled="multilingualToggle"
                inline
                >{{ option.categoryName }}</b-form-checkbox
              >
            </b-col>
          </b-form-row>
          <b-form-invalid-feedback class="d-block" v-if="validateCalendarCategories() && formSubmitted">Atleast one category required.</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="12" md="10" lg="10" xl="8">
        <b-form-group>
          <h4 class="page-sub-header">
            Layout
            <InheritText v-if="multilingualToggle" />
          </h4>
          <b-form-row>
            <b-col cols="12" sm="2" md="6" lg="10" class="layout-group">
              <b-form-radio-group
                class="mt-n1 custom-radio-layout"
                v-model="data.layout"
                id="radio-layoutOption"
                name="radio-layoutOption"
                :disabled="multilingualToggle"
              >
                <b-form-radio v-for="(option, fIndex) in layoutOptions" :value="option.value" :key="fIndex" class="custom-radio-spacing"
                  >{{ option.text }}<sup class="pl-2" :id="option.value"><BIconInfoCircle scale="1.4" /></sup>
                  <b-popover :target="option.value" placement="auto" triggers="hover focus" custom-class="format-results-popover">
                    <img :src="require(`@/assets/img/${option.value}-help.png`)" :alt="option.text" class="img-fluid" />
                  </b-popover>
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-form-row>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="dashboard-items"
              class="form-control"
              :options="widgetDashboardCountList"
              v-model.number="data.dashboardItemCnt"
              @input="data.dashboardItemCnt = $event"
              :class="{
                'is-value-exist': data.dashboardItemCnt != null,
                'is-invalid': !data.dashboardItemCnt && formSubmitted,
              }"
              :disabled="multilingualToggle"
            >
            </b-form-select>
            <label for="dashboard-items"
              >Dashboard items
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!data.dashboardItemCnt && formSubmitted">Dashboard items required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="pt-md-2 mt-md-1"> Recommended: 3 entries</b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="dashboard-expanded-view-items"
              class="form-control"
              :options="widgetDashboardCountList"
              v-model.number="data.expViewItemCnt"
              @input="data.expViewItemCnt = $event"
              :class="{
                'is-value-exist': data.expViewItemCnt != null,
                'is-invalid': !data.expViewItemCnt && formSubmitted,
              }"
              :disabled="multilingualToggle"
            >
            </b-form-select>
            <label for="dashboard-expanded-view-items"
              >Expanded view items
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!data.expViewItemCnt && formSubmitted">Expanded view items required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="pt-md-2 mt-md-1"> Recommended: 5 entries</b-col>
    </b-form-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { WidgetDashboardCountList } from '@/utilities/constants'
import { BIconInfoCircle } from 'bootstrap-vue'
export default {
  name: 'CalendarWidget',
  props: ['data', 'tenantId', 'culture', 'defaultCulture', 'formSubmitted', 'isEditPage'],
  data() {
    return {
      widgetDashboardCountList: WidgetDashboardCountList,
      focusedCalendar: false,
      layoutOptions: [
        { text: 'One-column List', value: 'one-column-list' },
        { text: 'Two-column List', value: 'two-column-list' },
        { text: 'One-column Grid', value: 'one-column-grid' },
      ],
    }
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.culture !== this.defaultCulture
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      selectedTenant: (state) => state.common.selectedTenant,
      calendarTypeOptions: (state) => state.widgets.calendarTypeOptions,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  components: {
    BIconInfoCircle,
    InheritText: () => import('../../common/InheritText.vue'),
  },
  mounted() {
    this.getCalendarTypes()
  },
  methods: {
    getCalendarTypes() {
      if (this.selectedTenant.length) {
        this.$store
          .dispatch('widgets/getCalendarTypes', this.isEditPage ? (this.userRole === 'SysAdmin' ? this.tenantId : null) : null)
          .then((res) => {
            if (res && res.data) {
              const result = this.data.selectedCalendarTypes.filter((sData) => res.data.find((fData) => sData.fieldSetID === fData.fieldSetID))
              this.data.selectedCalendarTypes = [...result]
              if (this.data.selectedCalendarTypes.length) {
                this.getSelectedCalendarCategories(this.data.selectedCalendarTypes)
              } else {
                this.data.categories = []
              }
            } else {
              this.data.selectedCalendarTypes = []
              this.data.categories = []
            }
          })
          .catch(() => {
            this.data.selectedCalendarTypes = []
            this.data.categories = []
          })
      }
    },
    getSelectedCalendarCategories(calendars) {
      this.data.selectedCalendarTypes = calendars
      const postObj = {
        tenantId: this.isEditPage ? (this.userRole === 'SysAdmin' ? this.tenantId : null) : null,
        fieldSetIds: calendars.map((calendar) => calendar.fieldSetID),
      }
      // this.data.categories = [];
      this.$store
        .dispatch('widgets/getCalendarCategories', postObj)
        .then((response) => {
          response.data.forEach((data) => {
            const calenderCategory = this.data.categories.find((item) => item.categoryId === data.categoryId)
            data.isSelected = calenderCategory ? calenderCategory.isSelected : data.isSelected
          })
          this.data.categories = [...response.data]
        })
        .catch(() => {
          this.data.categories = []
        })
    },
    validateCalendarCategories() {
      return this.data.categories.filter((category) => category.isSelected === true).length === 0
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      if (!this.isEditPage) {
        this.getCalendarTypes()
        this.data.selectedCalendarTypes = []
        this.data.categories = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-radio-spacing {
  margin-bottom: 10px;
}
</style>
